import React from "react";
import loadable from "@loadable/component";

const Vidazoo = loadable(() => import("../vendor/Vidazoo"));

const VideoAd = () => {
  return (
    <div className="adunit adunit--video">
      <div className="adunit__container">
        <Vidazoo vidazooId="659d69a7ca46d867c34ed89b" />
      </div>
    </div>
  );
};

export default VideoAd;
